import { IconButton, makeStyles } from "@material-ui/core"
import { forwardRef } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { ReactComponent as LeftArrow } from "../icons/left-arrow-sm.svg"
import { ReactComponent as RightArrow } from "../icons/right-arrow-sm.svg"

const NavArrow = styled(IconButton)`
  position: absolute;
  top: 50%;
  z-index: 1;
  padding: 0;
  width: 50px;
  &:hover {
    background-color: transparent;
  }
`

export const PrevArrow = ({ className, onClick, ...props }) => {
  const style = {
    ...props.style,
    position: `absolute`,
    left: 0,
    transform: `translateX(-100%)`,
  }
  return (
    <NavArrow style={style} onClick={onClick}>
      <LeftArrow />
    </NavArrow>
  )
}
export const NextArrow = ({ className, onClick, ...props }) => {
  const style = {
    ...props.style,
    position: `absolute`,
    right: 0,
    transform: `translateX(100%)`,
  }
  return (
    <NavArrow style={style} onClick={onClick}>
      <RightArrow />
    </NavArrow>
  )
}

const useStyles = makeStyles({
  "@global": {
    ".slick-list, .slick-track": {
      touchAction: `pan-y`,
    },
  },
})

const Carousel = forwardRef(({ children, ...overrides }, ref = null) => {
  const classes = useStyles()
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    autoplay: true,
    autoplaySpeed: 5000,
    ...overrides,
  }
  if (settings.arrows) {
    settings.className = classes.slider
  }
  return (
    <Slider ref={ref} {...settings}>
      {children}
    </Slider>
  )
})

export default Carousel
